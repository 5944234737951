import { ModelId } from "@src/utils/model/constants";
import { v1 } from "uuid";
import { z } from "zod";

const LiteralSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof LiteralSchema>;
type Json = Literal | { [key: string]: Json } | Json[];
export const JsonValueSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([LiteralSchema, z.array(JsonValueSchema), z.record(JsonValueSchema)])
);

export const JsonObjectSchema = z.record(JsonValueSchema);
export type JsonObject = z.infer<typeof JsonObjectSchema>;
export type JsonValue = z.infer<typeof JsonValueSchema>;

export function $uuid() {
  return v1().replace(/^(.{4})(.{4})-(.{4})-(.{4})/, "$4$3-$1-$2");
}

export namespace abstract {
  export type Value = Json;

  interface FieldBase {
    datasetId: string;
    name: string;

    type: string;
  }

  export type StringField = FieldBase & {
    type: "string";

    properties: {};
  };

  export type GPT3Field = FieldBase & {
    type: "gpt-3";
    properties: {
      prompt: string;
    };
  };

  export type EmbeddingField = FieldBase & {
    type: "embedding";
    properties: {
      template: string;
    };
  };

  export type Field = StringField | GPT3Field | EmbeddingField;

  export interface Embedding {
    datasetId: string;
    documents: string[];
  }

  export interface EmbeddingDocumentChunk {
    index: number;
    documentId: string;
  }

  export interface User {
    id: string;
    email: string;
    name: string | null;
  }
}

export const FieldSchema = z.intersection(
  z.object({
    datasetId: z.string(),
    name: z.string(),
  }),
  z.union([
    z.object({
      type: z.literal("string"),
      properties: z.object({}),
    }),
    z.object({
      type: z.literal("gpt-3"),
      properties: z.object({
        prompt: z.string(),
      }),
    }),
    z.object({
      type: z.literal("embedding"),
      properties: z.object({
        template: z.string(),
      }),
    }),
  ])
);

export type ChatConfig = {
  botName: string;
  botAvatar: string;
  icon: string;
  model: ModelId;
  temperature: number;
  inputTokenLimit: number;
  outputTokenLimit: number;
  threadLimit: number;
  threadLimitDuration: number;
  threadMessageLimit: number;
  threadMessageLimitDuration: number;
  themePrimary: string;
  greeting: string;
  placeholder: string;
  removeOurBranding: boolean;
  domain: string;
  handoff: string;
  incomingHook?: string;
  useEndUserKey: boolean;
  preprocessUrl: boolean;
  ratingFeature: boolean;
};

export const defaultChatConfig = {
  botName: "Assistant",
  botAvatar: "",
  icon: "",
  model: "gpt-4o-mini",
  temperature: 1,
  inputTokenLimit: 3400,
  outputTokenLimit: 600,
  threadLimit: 10000,
  threadLimitDuration: 30,
  threadMessageLimit: 10000,
  threadMessageLimitDuration: 30,
  themePrimary: "5038ff",
  greeting: "Hey! How can I help you today?",
  placeholder: "Type your message here...",
  removeOurBranding: false,
  domain: "",
  handoff: "",
  useEndUserKey: false,
  preprocessUrl: false,
  ratingFeature: false,
} as const satisfies ChatConfig;
